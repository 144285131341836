import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import { FaHeart } from 'react-icons/fa';

function About() {
    return (
    <div className="App">
        <Container className='Box'>
            <div>
                <p>
                <b>The Macro Medidor tracks multiple <span className='highlight-blue'>economic indicators</span> and <span className='highlight-green'>market prices</span>.</b>
                </p>
            </div>
            <div>
                <p><small><i>(<span className='highlight-blue'>Medidor</span><b> = </b><span className='highlight-green'>meter</span> in Portuguese)</i></small></p>
            </div>
            <div>
                <p>
                    Made with <FaHeart className= 'blue'/> by <a className='MJ-link' href="https://www.marcjohnson.info" target="_blank" rel="noopener noreferrer"><span className='highlight-greenblue'><b>marcjohnson.xyz</b></span></a>.
                </p>
            </div>
        </Container> 
    </div>
);
}

export default About;