import React from 'react';
import logo from './star_warz.ico';
import './App.css';

function Main() {
    return (
    <div className="App">
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" /> 
            <div  className='Content'>
                <p><b><span className='highlight-green'>Olá amigos</span></b></p>
                <p><b>Welcome to the <span className='highlight-blue'>Macro Medidor</span></b></p>
            </div>
        </header>
    </div>
);
}

export default Main;