import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/esm/Container';
import fetch from 'isomorphic-fetch';
import './portal.css';
// eslint-disable-next-line
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// eslint-disable-next-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";


const API_KEY = 'cb5fbb83e4b448faa253fc947af59f7f';
const EXCHANGE_RATE_ENDPOINT = 'https://openexchangerates.org/api/latest.json';
const COIN_GECKO_URL = 'https://api.coingecko.com/api/v3/simple/price';


function Dash() {
  
  const [exchangeRates, setExchangeRates] = useState({});
  const [filecoinPrice, setFilecoinPrice] = useState(null);
  const [ethereumPrice, setEthereumPrice] = useState(null);
  const [polkadotPrice, setPolkadotPrice] = useState(null);
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [priceRatio, setPriceRatio] = useState(null);
  const [priceRatioFIL, setPriceRatioFIL] = useState(null);
  const [priceRatioBTC, setPriceRatioBTC] = useState(null);
  // eslint-disable-next-line
  const [sp500MostRecentClosePrice, setSP500MostRecentClosePrice] = useState(0);
  // eslint-disable-next-line
  const [nasdaqMostRecentClosePrice, setNasdaqMostRecentClosePrice] = useState(0);
  // eslint-disable-next-line
  const [djiaMostRecentClosePrice, setDJIAMostRecentClosePrice] = useState(0);


  const formatAsCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  }

  const formattedFIL = formatAsCurrency(filecoinPrice);
  const formattedETH = formatAsCurrency(ethereumPrice);
  const formattedDOT = formatAsCurrency(polkadotPrice);
  const formattedBTC = formatAsCurrency(bitcoinPrice);

  
  useEffect(() => {
    const fetchData = async () => {
    
        try {
      // Fetch the exchange rates from the EXCHANGE_RATE_ENDPOINT
      const exchangeRatesResponse = await fetch(`${EXCHANGE_RATE_ENDPOINT}?app_id=${API_KEY}`);
      const exchangeRatesData = await exchangeRatesResponse.json();
      // Set the exchange rates in the state
      setExchangeRates({
        USD_EUR: (exchangeRatesData.rates.USD / exchangeRatesData.rates.EUR).toFixed(3),
        USD_GBP: (exchangeRatesData.rates.USD / exchangeRatesData.rates.GBP).toFixed(3),
        CNY_USD: (exchangeRatesData.rates.CNY / exchangeRatesData.rates.USD).toFixed(3),
      });
        } catch (error) {
            console.error(error);  
        }
    
        try {  
        // Fetch the current price of the cryptocurrency from the CRYPTO_ENDPOINT
        const cryptoResponse = await fetch(`${COIN_GECKO_URL}?ids=filecoin,ethereum,polkadot,bitcoin&vs_currencies=usd`);
        const cryptoData = await cryptoResponse.json();
        setPolkadotPrice(cryptoData.polkadot.usd);
        setFilecoinPrice(cryptoData.filecoin.usd);
        setEthereumPrice(cryptoData.ethereum.usd);
        setBitcoinPrice(cryptoData.bitcoin.usd);
        setPriceRatio((cryptoData.ethereum.usd / cryptoData.filecoin.usd).toFixed(2));
        setPriceRatioFIL((cryptoData.polkadot.usd / cryptoData.filecoin.usd).toFixed(2));
        setPriceRatioBTC((cryptoData.bitcoin.usd / cryptoData.ethereum.usd).toFixed(2));
        } catch (error) {
            console.error(error);
        }

        try {
            // Make a request to the /api/indices endpoint using fetch
            const response = await fetch('http://localhost:3000/api/indices');
            const data = await response.json();
            
            // Update the state variables with the most recent close prices of the indices
            setSP500MostRecentClosePrice(data.sp500MostRecentClosePrice);
            setNasdaqMostRecentClosePrice(data.nasdaqMostRecentClosePrice);
            setDJIAMostRecentClosePrice(data.djiaMostRecentClosePrice);
          } catch (error) {
            console.error(error);
          }
        }
    
    fetchData();     

}, []);

  return (
    <div>
      <div className="Portal-box" >
        <Container className='table'>
        <h3><span className='highlight highlight-blue'><u>Ex. Rates</u></span></h3>
        <table>
            <tr className='tableText'>USD/EUR: {exchangeRates.USD_EUR}</tr>
            <tr className='tableText'>USD/GBP: {exchangeRates.USD_GBP}</tr>
            <tr className='tableText'>CNY/USD: {exchangeRates.CNY_USD}</tr>
        </table>
        </Container>

        <Container className='table'>
        <h3><span className='highlight highlight-greenblue'><u>Crypto Ratios</u></span></h3>
        <table>
        <tr className='tableText'>BTC/ETH: {priceRatioBTC}</tr> 
        <tr className='tableText'>ETH/FIL: {priceRatio}</tr> 
        <tr className='tableText'>DOT/FIL: {priceRatioFIL}</tr> 
        </table>
        </Container>


        <Container className='table'>
        <h3><span className='highlight highlight-green'><u>Crypto</u></span></h3>
        <table>
        <tr className='tableText'>BTC: {formattedBTC}</tr> 
        <tr className='tableText'>ETH: {formattedETH}</tr> 
        <tr className='tableText'>FIL: {formattedFIL}</tr> 
        <tr className='tableText'>DOT: {formattedDOT}</tr>  
        </table>
        </Container>
      </div>
    </div>
  );
}
export default Dash;

 /*
      <Container className='table'>
        <h3><span className='highlight highlight-blue'><u>Yields</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    US Treasury Yield Curve Rates
           </Tooltip>
           }
           delay={{ show: 250, hide: 400 }}
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>1yr: </tr> 
        <tr className='tableText'>2yr: </tr> 
        <tr className='tableText'>10yr: </tr> 
        <tr className='tableText'>30yr: </tr> 
        </table>
    
      </Container>

      <Container className='table'>
        <h3><span className='highlight highlight-greenblue'><u>Equities</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    DJIA, S&P 500, NASDAQ, & FTSE Indicies
           </Tooltip>
           }
           delay={{ show: 250, hide: 400 }}
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>DJIA: {djiaMostRecentClosePrice}</tr> 
        <tr className='tableText'>S&P 500: {sp500MostRecentClosePrice}</tr> 
        <tr className='tableText'>NASDAQ: {nasdaqMostRecentClosePrice}</tr> 
        <tr className='tableText'>FTSE: {nasdaqMostRecentClosePrice}</tr> 
        </table>

      </Container>

      <Container className='table'>
        <h3><span className='highlight highlight-green'><u>Commodities</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                     <a href="https://marcjohnson.info" target="_blank" rel="noopener noreferrer">Hello World</a>.
                </Tooltip>
                }
                delay={{ show: 250, hide: 400 }}
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>WTI: </tr>
        <tr className='tableText'>NAT GAS: </tr>
        <tr className='tableText'>Silver: </tr>
        <tr className='tableText'>Copper: </tr>
        </table>
      </Container>

      <Container className='table'>
        <h3><span className='highlight highlight-blue'><u>Maritime</u></span>
        <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    Baltic Dry Index (BDI), Capesize Freight Index (CFI), Global Ave. Freight Rate (GFR), Monthly Ave. Port Calls (MPC)
                </Tooltip>
                }
                delay={{ show: 250, hide: 400 }}
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>BDI: </tr>
        <tr className='tableText'>CFI: </tr> 
        <tr className='tableText'>GFR: </tr>
        <tr className='tableText'>MPC: </tr> 
        </table>
      </Container>

      <Container className='table'>
        <h3><span className='highlight highlight-greenblue'><u>Leading</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    Prominent Leading Indicators
                </Tooltip>
                }
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>H.Starts: </tr>
        <tr className='tableText'>PMI: </tr>
        <tr className='tableText'>CPI: </tr>
        <tr className='tableText'>PPI: </tr>
        </table>
      </Container>
      <Container className='table'>
        <h3><span className='highlight highlight-green'><u>Lagging</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    Prominent Lagging Indicators
                </Tooltip>
                }
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>W.Grth: </tr>
        <tr className='tableText'>Unempt: </tr>
        <tr className='tableText'>GDP.G: </tr>
        <tr className='tableText'>C.Prfts: </tr>
        </table>
      </Container>
      <Container className='table'>
        <h3><span className='highlight highlight-blue'><u>Sentiment</u></span>
          <OverlayTrigger placement="right" overlay={ 
                <Tooltip id="tooltip-right" className="info-box">
                    Prominent Sentiment Indicators
                </Tooltip>
                }
            > 
                <FontAwesomeIcon icon={faInfoCircle} className="fa" />
          </OverlayTrigger>
        </h3>
        <table>
        <tr className='tableText'>UMich: </tr>
        <tr className='tableText'>Con Conf: </tr>
        <tr className='tableText'>VIX: </tr>
        <tr className='tableText'>F & G: </tr>
        </table>
      </Container> 
      
      */